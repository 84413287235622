<template>
    <label class="checkbox">
        <input type="checkbox" class="checkbox-input" v-model="model" :value="value">
        <div class="checkbox-label">
            <span class="checkbox-border"></span>
            <span class="checkbox__text">{{ text }}</span>
            <span class="checkbox__price">{{ price }}</span>
        </div>
    </label>
</template>

<script>
import { computed } from 'vue'

export default {
    name: "InputCheckbox",
    props: {
        modelValue: '',
        price: {
            type: String,
            nullable: false
        },
        value: {
            type: String,
            nullable: false
        },
        text: {
            type: String,
            nullable: false
        }
    },
    setup(props, {emit}) {
        const model = computed({
            get() {
                return props.modelValue
            },
            set(value) {
                emit('update:modelValue', value)
            }
        })

        return {
            model
        }
    }
}
</script>

<style scoped lang="scss">
.checkbox {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    background-color: $color-grey-50;

    border: .1rem solid $color-primary-black;
    border-radius: .4rem;

    line-height: 1.7rem;

    padding: 1.2rem;

    &:not(:last-of-type) {
        margin-right: 1.2rem;
    }

    &-input {
        visibility: hidden;
    }

    &-label {
        position: relative;
        padding-left: 35px;

        &:after {
            content: "";
            display: block;
            width: 1rem;
            height: 1rem;
            position: absolute;
            left: 3px;
            top: 3px;
            border-radius: .2rem;
        }
    }

    &-border {
        width: 1.6rem;
        height: 1.6rem;
        display: inline-block;
        outline: dashed 1px $color-black;
        border-radius: .4rem;
        position: absolute;
        left: 0;
        top: 0;
    }

    &-input:checked + &-label:after {
        background-color: $color-black;
    }

    &__text {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-secondary-greyscale;
    }

    &__price {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-primary-black;
        margin-left: .75rem;
    }
}
</style>
