<template>
    <label class="radio">
        <input type="radio" class="radio-input" :name="name" :value="value" :checked="modelValue === value"
               @change="updateValue">
        <div class="radio-label">
            <span class="radio-border"></span>
            <span class="radio__text">{{ text }}</span>
            <span class="radio__price">{{ price }}</span>
        </div>
    </label>
</template>

<script>
export default {
    name: "InputRadioButton",
    props: {
        modelValue: '',
        name: {
            type: String,
            default: ""
        },
        price: {
            type: String,
            nullable: false
        },
        value: {
            type: String,
            nullable: false
        },
        text: {
            type: String,
            nullable: false
        }
    },
    setup(props, context) {
        const updateValue = (event) => {
            context.emit('update:modelValue', event.target.value)
        }
        return {
            updateValue
        }
    }
}
</script>

<style scoped lang="scss">
.radio {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    background-color: $color-grey-50;

    border: .1rem solid $color-primary-black;
    border-radius: .4rem;

    line-height: 1.7rem;

    padding: 1.2rem;

    &-input {
        visibility: hidden;
    }

    &-label {
        position: relative;
        padding-left: 35px;

        &:after {
            content: "";
            display: block;
            width: .8rem;
            height: .8rem;
            position: absolute;
            left: 4px;
            top: 4px;
            border-radius: 50%;
        }
    }

    &-border {
        width: 1.6rem;
        height: 1.6rem;
        display: inline-block;
        outline: dashed 1px $color-black;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &-input:checked + &-label:after {
        background-color: $color-black;
    }

    &__text {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-secondary-greyscale;
    }

    &__price {
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-primary-black;
        margin-left: .75rem;
    }
}

</style>
