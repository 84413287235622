<template>
    <div class="container">
        <slot name="sidebar-left"/>
        <div class="main-container">
            <div class="navbar__container">
                <div class="navbar__content" id="navbar-content">
                    <a href="https://studiozebra.com/" class="navbar__logo">
                        <img src="/images/studio_zebra_logo.svg"/>
                    </a>
                    <div class="navbar__nav-items">
                        <a href="https://studiozebra.com/producten/het-vakantieboek/" class="navbar__nav-item nav-link">Het vakantieboek</a>
                        <a href="https://studiozebra.com/producten/speurtocht-op-maat/" class="navbar__nav-item nav-link">Speurtocht op maat</a>
                        <a href="https://studiozebra.com#over-ons" class="navbar__nav-item nav-link">Over ons</a>
                        <a href="https://studiozebra.com#contact" class="navbar__nav-item nav-link">Contact</a>
                    </div>
                </div>
            </div>
            <div class="header">
                <div class="header__section header__section-left">
                    <slot name="header-left"/>
                </div>
                <div class="header__section header__section-right">
                    <slot name="header-right"/>
                </div>
                <div class="header__section header__section--full-width">
                    <slot name="header-bottom"/>
                </div>
                <div class="header__section">
                    <slot name="header-bottom-left"/>
                </div>
                <div class="header__section">
                    <slot name="header-bottom-right"/>
                </div>
            </div>
            <div class="content">
                <slot name="content"/>
            </div>
        </div>
        <slot name="sidebar-right"/>
    </div>
</template>
<script>
export default {
    name: "Layout"
}
</script>

<style scoped lang="scss">
.header {
    background-color: $color-secondary-sandstorm;
    padding: 4rem 12rem;
    border-bottom: .1rem solid $color-black;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 1330px) {
        flex-direction: column;
    }

    @media only screen and (max-width: 899px) {
        padding: 2rem 4rem;
    }

    &__section {
        width: 50%;

        @include mq('laptop-lg') {
            width: 100%;
        }

        &--full-width {
            width: 100%;
        }
    }

    &-intro-select {
        &__container {
            display: flex;
        }
    }
}
.navbar {
    &__container {
        background-color: $color-secondary-sandstorm;
        border-bottom: .1rem solid $color-black;
        display: flex;
        justify-content: center;
        padding: 2rem 0;
    }

    &__content {
        display: flex;
        width: 1140px;
        @include mq('laptop') {
            width: calc(100vw - 2rem);
            justify-content: center;
        }

        &--collapsed {
            width: calc(100vw - 50rem);
            justify-content: center;

            .navbar__nav-items {
                display: none;
            }

            .navbar__logo {
                width: auto;
            }
        }
    }

    &__nav-items {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 69.332%;
        @include mq('laptop') {
            display: none;
        }
    }

    &__nav-item {
        padding: 1.3rem 2rem;
        &:hover {
            text-decoration: underline;
        }
    }

    &__logo {
        width: 15%;

        @include mq('laptop') {
            width: auto;
        }

        img {
            width: 125px;
            height: 67.64px;
        }
    }
}
.container {
    display: flex;
    align-items: stretch;
}

.main-container {
    flex-grow: 1;
    position: relative;
}

.sidebar {
    &--right {
        flex: 0 0 320px;
        border-left: 1px solid $color-black;
        background-color: $color-secondary-sandstorm;
    }
}

html {
    height: 100%;
}

.content {
    padding: 4rem 12rem;
    padding-bottom: 10rem;

    @media only screen and (max-width: 899px) {
        padding: 2rem 4rem;
        padding-bottom: 8rem;
    }
}
</style>
