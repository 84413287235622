import './bootstrap'
import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from '@pages/App'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSplide from '@splidejs/vue-splide'

//Icons
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import {
    faArrowRight,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faCamera,
    faPencil,
    faMagnifyingGlass,
    faChevronRight,
    faChevronLeft,
    faImage,
    faPen,
    faTimes,
    faShoppingBasket,
    faSpinner,
    faTrash,
    faCrop,
    faBrush,
    faPlus
} from '@fortawesome/pro-regular-svg-icons'

library.add(
    faArrowRight,
    faCamera,
    faImage,
    faPencil,
    faMagnifyingGlass,
    faChevronRight,
    faChevronLeft,
    faPen,
    faTimes,
    faShoppingBasket,
    faSpinner,
    faTrash,
    faCrop,
    faBrush,
    faPlus
)

const pinia = createPinia()
const app = createApp({})

app.component('app', App)
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(pinia)
app.use(VueSplide)
app.use(VueAxios, axios)

app.provide('axios', app.config.globalProperties.axios)

app.mount('#app')
