<template>
    <pick-existing-images/>
</template>
<script>
import PickExistingImages from "@steps/PickExistingImages";

import {useQuestcardStore} from "@stores/questcardStore";
import { ref } from "vue"

export default {
    name: "App",
    setup() {
    },
    components: {
        PickExistingImages
    },
}
</script>
