<template>
    <div class="sticky-footer__container">
        <div class="sticky-footer__text">
            <p class="text--medium">{{ topText }}</p>
            <p class="text--small">{{ bottomText }}</p>
        </div>
        <div>
            <input-button
                :icon="'fa-solid fa-arrow-right'"
                :iconLocation="'right'"
                :color="`${buttonColor} btn--text-small`"
                :enabled="buttonEnabled"
                @click="$emit('footer-button-clicked')"
            >
                Bestelopties
            </input-button>
        </div>
    </div>
</template>

<script>
import InputButton from "@components/inputs/InputButton";

export default {
    name: "StickyFooter",
    props: {
        topText: {
            type: String,
            default: ''
        },
        bottomText: {
            type: String,
            default: ''
        },
        buttonColor: {
            type: String,
            default: 'grey'
        },
        buttonEnabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputButton
    },
}
</script>

<style scoped lang="scss">
.sticky-footer {
    &__container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: $color-secondary-sandstorm;
        padding: 1rem 12rem;
        border-top: 1px solid $color-black;

        @include mq('laptop'){
            padding: .6rem;
        }
    }

    &__text {
        text-align: right;
        margin-right: 1rem;

        p {
            @include mq('mobile') {
                text-align: left;
            }
        }
    }
}
</style>
