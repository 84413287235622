import {defineStore} from 'pinia'

export const useQuestcardStore = defineStore('questcard', {
    state: () => {
        return {
            form: {
                questcardType: document.getElementById('questcard_type').dataset['type'],
                amountImages: '12',
                questcardTitle: '',
                cartStep: 1,
                orderOptions: 'only_pdf',
                color: 'black_white',
                format: 'a4',
                amountPrints: '1'
            },
            questcards: []
        }
    },
    getters: {
        getImageById: (state) => {
            return (id) => {
                let image = state.questcards.find(qc => qc.id === id)
                if (image) {
                    return image.file.image
                }
                return null
            }
        }
    }
})
