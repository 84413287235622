<template>
    <div class="text-input__container">
        <label>
            <input class="text-input__input" type="text" @input="updateValue" :placeholder="placeholder">
            <font-awesome-icon
                class="text-input__input-icon"
                :class="iconSpin && ' text-input__input-icon--spinning fa-spin'"
                :icon="icon"/>
        </label>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: {
        modelValue: '',
        icon: {
            type: String,
            default: ""
        },
        iconSpin: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    setup(props, context) {
        const updateValue = (event) => {
            context.emit('update:modelValue', event.target.value)
        }
        return {
            updateValue
        }
    }
}
</script>

<style scoped lang="scss">
.text-input {
    &__container {
        position: relative;
        width: 100%;
        max-width: 50rem;
    }

    &__input {
        font-size: 1.6rem;
        color: $color-paragraph;
        padding: 1rem;
        border: solid 1px $color-black;
        border-radius: .4rem;
        width: 100%;

        &::placeholder {
            color: $color-grey-300;
        }
    }

    &__input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
        cursor: pointer;
        width: 1.8rem;
        height: 1.8rem;

        &--spinning {
            top: 30%;
        }
    }
}

</style>
