<template>
    <div class="image__container"
         :class="isSelected ? 'image__container--already-selected' : ''"
    >
        <div v-if="isSelected" class="image__item--already-selected">
            <input-button
                icon="fa-regular fa-trash"
                :iconLocation="'left'"
                color="outline btn--text-small"
                buttonClasses="hover--blue"
                @click.self="removeFromImages"
            >
                Verwijder uit speurtocht
            </input-button>
        </div>
        <img @click.self="imageClicked" v-if="file && file.image" :src="file.image" alt="">
    </div>
</template>

<script>
import InputButton from "@components/inputs/InputButton";

export default {
    name: "SelectExistingImage",
    props: {
        file: {
            default: null
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        const imageClicked = () => {
            if (!props.isSelected) {
                emit('image-clicked', props.file)
            }
        }

        const removeFromImages = () => {
            emit('remove-from-images', props.file)
        }

        return {
            imageClicked,
            removeFromImages
        }
    },
    components: {
        InputButton
    }
}
</script>

<style scoped lang="scss">
.image {
    &__container {
        border: 1px solid $color-black;
        width: 21rem;
        height: 12rem;
        cursor: pointer;

        &--already-selected {
            position: relative;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__item {
        &--already-selected {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
