<template>
    <div class="sidebar__steps-container">
        <div v-for="i in steps" class="sidebar__step" :class="questcardStore.form.cartStep === i && 'sidebar__step--active'">
            {{i}}
        </div>
    </div>
</template>

<script>
import {useQuestcardStore} from "@stores/questcardStore";
export default {
    name: "Steps",
    props: {
      steps: {
          type: Number,
          default: 3
      }
    },
    setup() {
        const questcardStore = useQuestcardStore()

        return {
            questcardStore
        }
    }
}
</script>

<style scoped lang="scss">
.sidebar {
    &__steps-container {
        display: flex;
        justify-content: center;
    }

    &__step {
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-black;
        font-size: 1.4rem;

        &:not(:last-of-type) {
            margin-right: .8rem;
        }

        &--active {
            color: $color-primary-white;
            background-color: $color-black;
        }
    }
}
</style>
