<template>
    <button :class="`btn btn--${color} btn--${enabled ? 'enabled' : 'disabled'} ${buttonClasses}`">
        <slot v-if="iconLocation === 'right'"></slot>
        <font-awesome-icon v-if="icon" :class="`icon-${iconLocation}`" :icon="icon"></font-awesome-icon>
        <img v-if="customIcon && iconSrc" :src="iconSrc"/>
        <slot v-if="iconLocation === 'left'"></slot>
    </button>
</template>

<script>
export default {
    name: "InputButton",
    props: {
        customIcon: {
            type: Boolean,
            default: false
        },
        iconSrc: {
            type: String,
            default: "",
        },
        buttonClasses: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        iconLocation: {
            type: String,
            default: 'right'
        },
        color: {
            type: String,
            default: 'green'
        },
        enabled: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped lang="scss">
.btn {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 1.6rem 2rem;
    border: .1rem solid $color-primary-black;
    border-radius: .4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &--enabled {
        cursor: pointer;
    }

    &--disabled {
        cursor: default;
        pointer-events: none;
    }

    &--green {
        background-color: $color-green;
        color: $color-primary-white;
    }

    &--black {
        background-color: $color-primary-black;
        color: $color-primary-white;
    }

    &--grey {
        background-color: $color-secondary-greyscale;
        color: $color-primary-white;
    }

    &--white {
        background-color: $color-primary-white;
        color: $color-primary-black;
    }

    &--outline {
        border: .1rem solid $color-black;
        background-color: white;
    }

    &--text-small {
        font-weight: 400;
        font-size: 1.2rem;
    }
}

.icon-right {
    margin-left: 1rem;
}

.icon-left {
    margin-right: 1rem;
}

.hover {
    &--blue:hover {
        color: $color-blue;
        border-color: $color-blue;
    }
}
</style>
