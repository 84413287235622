<template>
    <div
        class="sidebar"
        :class="`sidebar--${direction} sidebar--bg-${bgColor}`"
    >
        <div class="sidebar__content">
            <div class="sidebar__header">
                <slot name="sidebar-header">
                </slot>
            </div>
            <div class="sidebar__container">
                <slot name="sidebar-content">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import InputRadioButton from "@components/inputs/InputRadioButton";

import {computed} from "vue";

export default {
    name: "Sidebar",
    props: {
        direction: {
            type: String,
            default: 'right'
        },
        bgColor: {
            type: String,
            default: 'grey'
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {

        return {}
    },
    components: {
        InputRadioButton,
    }
}
</script>

<style scoped lang="scss">
.main-container {
    flex-grow: 1;
}

.sidebar {
    flex: 0 0 34rem;
    overflow: hidden;
    transition: all .2s ease-in-out;
    background-color: $color-secondary-sandstorm;

    &--bg-white {
        background-color: $color-white;
    }

    &--bg-grey {
        background-color: $color-secondary-sandstorm;
    }

    &--right {
        border-left: 1px solid $color-black;

        .sidebar__container {
            @include mq('laptop') {
                height: calc(100vh - 11rem);
            }
        }

        .sidebar__content {
            width: 34rem;
            padding-right: .1rem;

            @include mq('laptop') {
                width: 100%;
                overflow: auto;
                height: 100vh;
            }
        }

        @include mq('laptop') {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 999;
            right: 0;
            left: 0;
        }
    }

    &--left {
        border-right: 1px solid $color-black;
        flex: 0 0 48rem;

        .sidebar__container {
            @include mq('laptop') {
                height: calc(100vh - 19.2rem);
            }

            max-height: calc(100vh - 19rem);
        }

        .sidebar__content {
            width: 48rem;
            padding-right: .1rem;

            @include mq('laptop') {
                width: 100%;
            }
        }

        @include mq('laptop') {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 999;
            right: 0;
            left: 0;
        }
    }

    &__title {
        text-align: center;
    }

    &__header {
        padding: 1.2rem 1.2rem;
        border-bottom: 1px solid $color-black;

    }

    &__container {
        padding: 1.6rem;
        padding-right: 0rem;
        max-height: calc(100vh - 11rem);
        overflow: auto;
    }

    &__content {
        transition: all .2s ease-in-out;
        position: fixed;
    }

    &__collapsed {
        flex: 0 0 0;
        width: 0;
        visibility: hidden;

        .sidebar__content {
            width: 0;
        }
    }
}
</style>
