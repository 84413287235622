<template>
    <Layout>
        <template #header-left>
            <h1 class="title--large mb-4">Speurtocht op maat</h1>
            <p class="mb-4 text text--medium" v-if="questcardStore.form.questcardType !== 'photo_upload'">
                Speuren maar! Met onze illustraties kun je precies de speurtocht maken die je zoekt voor onderweg, een feestje, een stedentrip, een dagje uit of juist een dagje thuis.
We zijn benieuwd welke combinatie jij maakt! Veel (speur)plezier.
            </p>
            <p class="mb-4 text text--medium" v-else>
                Gaaf dat je met eigen foto’s een speurtocht samen gaat stellen en door ons laat tekenen!
                <br/>
                Hierbij geven we je wat meer uitleg hoe je de mooiste speurtocht als resultaat krijgt!
            </p>
        </template>
        <template #header-right>
            <h2 class="title--medium mb-4">Aantal plaatjes</h2>
            <div class="radio-input__container">
                <input-radio-button
                    name="image_amount"
                    text="12 plaatjes"
                    :price="`€ ${questcardStore.form.questcardType === 'existing_images' ? '1,29' : '600,-'}`"
                    value="12"
                    v-model="questcardStore.form.amountImages"
                />
                <input-radio-button
                    name="image_amount"
                    text="15 plaatjes"
                    :price="`€ ${questcardStore.form.questcardType === 'existing_images' ? '1,49' : '750,-'}`"
                    value="15"
                    v-model="questcardStore.form.amountImages"
                />
                <input-radio-button
                    name="image_amount"
                    text="20 plaatjes"
                    :price="`€ ${questcardStore.form.questcardType === 'existing_images' ? '1,69' : '1000,-'}`"
                    value="20"
                    v-model="questcardStore.form.amountImages"
                />
            </div>
        </template>
        <template #header-bottom v-if="questcardStore.form.questcardType === 'photo_upload'">
            <h3 class="title--small mt-4 mb-4">Tips voor optimale illustraties</h3>
        </template>
        <template #header-bottom-left v-if="questcardStore.form.questcardType === 'photo_upload'">
            <div class="questcard__tips">
                <font-awesome-icon icon="fa-regular fa-crop"/>
                <p class="mb-4 text text--medium">
                    Wil je een persoon laten tekenen?
                    Zorg dan dat alleen de persoon die je getekent wilt hebben op de foto staat of voeg een duidelijke
                    instructie toe.
                    Zoals ‘tweede persoon van links’
                </p>
            </div>
        </template>
        <template #header-bottom-right v-if="questcardStore.form.questcardType === 'photo_upload'">
            <div class="questcard__tips">
                <font-awesome-icon icon="fa-regular fa-brush"/>
                <p class="mb-4 text text--medium">
                    Wij hebben een eigen huisstijl. Dit betekent dat we niet aan alle wensen rondom stijl kunnen
                    voldoen.
                </p>
            </div>
        </template>
        <template #sidebar-left v-if="questcardStore.form.questcardType !== 'photo_upload'">
            <sidebar :class="collapsableClass" direction="left" bgColor="white">
                <template #sidebar-header>
                    <input-text class="mb-4"
                                :icon="`fa-regular ${searching ? 'fa-spinner' : 'fa-pencil'}`"
                                :iconSpin="searching"
                                placeholder="Illustraties doorzoeken"
                                v-model="searchString"/>
                    <div class="filter__checkbox-scroll-container">
                        <Splide :options="splideOptions" aria-label="My Favorite Images">
                            <SplideSlide v-for="tag in popularTags">
                                <input-checkbox :text="tag.name" :value="tag.name" v-model="checkedTags"/>
                            </SplideSlide>
                        </Splide>


                    </div>
                </template>
                <template #sidebar-content>
                    <button @click="setSidebarCollapsed(!sideBarCollapsed)" class="sidebar__collapse-button-mobile">
                        <font-awesome-icon class="text-input__input-icon" :icon="'fa-regular fa-chevron-left'"/>
                    </button>
                    <div class="image__select-container">
                        <select-existing-image
                            v-for="imageResult in imageResults"
                            :file="imageResult"
                            @image-clicked="setImageOnImageCard"
                            @remove-from-images="removeFromImages"
                            :isSelected="isImageDouble(imageResult.id)"
                        />
                    </div>
                    <div class="image__load-more">
                        <input-button
                            icon="fa-regular fa-plus"
                            @click="loadMore"
                        >
                            Meer
                        </input-button>
                    </div>
                </template>
            </sidebar>
        </template>
        <template #content>
            <button
                v-if="questcardStore.form.questcardType !== 'photo_upload'"
                @click="setSidebarCollapsed(!sideBarCollapsed)"
                class="sidebar__collapse-button"
                :class="!sideBarCollapsed && 'sidebar__collapse-button--open'"
            >
                <font-awesome-icon
                    class="sidebar__collapse-button-icon"
                    :icon="sideBarArrow"
                />
            </button>
            <h2 class="title--medium mb-4">Titel speurtocht</h2>
            <input-text
                v-model="questcardStore.form.questcardTitle"
                placeholder="Voer een titel in voor de speurtocht"
                icon="fa-regular fa-pen"
            />
            <div class="image__container mt-4">
                <image-upload
                    v-if="questcardStore.form.questcardType === 'photo_upload'"
                    :class="`item item-${questcardStore.form.amountImages}`"
                    v-for="i in Array(parseInt(questcardStore.form.amountImages)).keys()"
                    :identifier="i"
                    :selected="selectedImageCard === i"
                    @button-clicked="setSelectedImageCard"
                    @image-uploaded="imageUploaded"
                />
                <image-select
                    v-else
                    v-for="i in Array(parseInt(questcardStore.form.amountImages)).keys()"
                    buttonIcon="fa-regular fa-image"
                    buttonText="Kies illustratie"
                    :identifier="i"
                    :selected="selectedImageCard === i"
                    :class="`item item-${questcardStore.form.amountImages}`"
                    :image="questcardStore.getImageById(i)"
                    @button-clicked="setSelectedImageCard"
                />
            </div>
        </template>
        <template #sidebar-right>
            <cart :cartCollapsed="cartCollapsed" @close-cart="setCartCollapsed(true)"></cart>
        </template>
    </Layout>
    <sticky-footer
        :top-text="footerOptions.topText"
        :bottom-text="footerOptions.bottomText"
        :button-color="footerOptions.buttonColor"
        :button-enabled="footerOptions.buttonEnabled"
        @footer-button-clicked="setCartCollapsed(false)"
    />
</template>

<script>

import Layout from "@layouts/Layout";
import Sidebar from "@layouts/Sidebar";
import StickyFooter from "@layouts/StickyFooter";

import InputRadioButton from "@components/inputs/InputRadioButton";
import InputText from "@components/inputs/InputText";
import ImageSelect from "@components/inputs/ImageSelect";
import ImageUpload from "@components/inputs/ImageUpload";
import InputCheckbox from "@components/inputs/InputCheckbox";
import InputButton from "@components/inputs/InputButton";

import SelectExistingImage from "@components/sidebar/SelectExistingImage";
import Cart from "@components/cart/Cart"

import {useQuestcardStore} from "@stores/questcardStore";
import apiClient from "@services/api";


import {ref, watch, computed, onMounted, defineProps} from "vue";
import '@splidejs/vue-splide/css';

export default {
    name: "PickExistingImages",
    components: {
        InputButton,
        Layout,
        Sidebar,
        StickyFooter,
        InputRadioButton,
        InputText,
        ImageSelect,
        SelectExistingImage,
        InputCheckbox,
        ImageUpload,
        Cart
    },
    setup() {
        const questcardStore = useQuestcardStore()
        const selectedImageCard = ref(0)
        const sideBarCollapsed = ref(true)
        const cartCollapsed = ref(true)
        const imageResults = ref([])
        const popularTags = ref([])
        const checkedTags = ref([])
        const searchString = ref([])
        const searchTimer = ref(null)
        const searching = ref(false)
        const searchPage = ref(1)

        const splideOptions = {
            autoWidth: true,
            pagination: false,
            arrows: false,
            drag: 'free',
            gap: '1rem'
        };

        const setSelectedImageCard = (data) => {
            selectedImageCard.value = data
            if (questcardStore.form.questcardType !== 'photo_upload') {
                setSidebarCollapsed(false)
            }
        }

        const setImageOnImageCard = (data) => {
            let index = questcardStore.questcards.findIndex(qc => qc.id === selectedImageCard.value)

            if (index >= 0) {
                questcardStore.questcards.splice(index, 1)
            }

            questcardStore.questcards.push({
                id: selectedImageCard.value,
                file: {
                    ...data
                }
            })
            if (window.innerWidth <= 1199) {
                setSidebarCollapsed(true)
            }
        }

        const imageUploaded = (data) => {
            questcardStore.questcards.push({
                id: selectedImageCard.value,
                file: {
                    ...data
                }
            })
        }

        const removeFromImages = (file) => {
            questcardStore.questcards.splice(questcardStore.questcards.indexOf(questcardStore.questcards.find((qc) => qc.file.id === file.id)), 1)
        }

        const setCartCollapsed = (collapsed) => {
            cartCollapsed.value = collapsed
            if (!collapsed) {
                sideBarCollapsed.value = true
            }
            addOrRemoveBodyClass()
        }

        const setSidebarCollapsed = (collapsed) => {
            sideBarCollapsed.value = collapsed
            if (!collapsed) {
                cartCollapsed.value = true
            }
            addOrRemoveBodyClass()
        }

        const addOrRemoveBodyClass = () => {
            const body = document.querySelector('body')
            const navbar = document.getElementById('navbar-content')
            if (!sideBarCollapsed.value || !cartCollapsed.value) {
                body.classList.add('body__collapsed')
                navbar.classList.add('navbar__content--collapsed')
            } else {
                body.classList.remove('body__collapsed')
                navbar.classList.remove('navbar__content--collapsed')
            }
        }

        const searchImages = () => {
            if (!checkedTags.value.length > 0 && !searchString) {
                getImages()
                return
            }
            let string = '?search='
            if (searchString.value) string += searchString.value
            if (checkedTags.value.length) string += '&tags[]=' + checkedTags.value.join('&tags[]=')

            apiClient.get(`/images${string}`).then((resp) => {
                imageResults.value = resp.data.data
                searching.value = false
            })
        }

        const getImages = () => {
            apiClient.get('/images').then((resp) => {
                imageResults.value = resp.data.data
                searching.value = false
            })
        }

        const isImageDouble = (id) => {
            return questcardStore.questcards.some((qc) => qc.file.id === id)
        }

        const footerOptions = computed(() => {
            let totalTargetImages = parseInt(questcardStore.form.amountImages)
            let currentTotalImages = questcardStore.questcards.length
            if (currentTotalImages < totalTargetImages) {
                return {
                    topText: `Kies nog ${totalTargetImages - currentTotalImages} illustraties`,
                    bottomText: "Ga daarna naar bestelopties",
                    buttonColor: "grey",
                    buttonEnabled: false
                }
            }

            if (!questcardStore.form.questcardTitle) {
                return {
                    topText: `Vul een speurtocht titel in`,
                    bottomText: "Ga daarna naar bestelopties",
                    buttonColor: "grey",
                    buttonEnabled: false
                }
            }

            return {
                topText: "Alle foto's zijn gevuld!",
                bottomText: "Ga door naar bestelopties",
                buttonColor: "green",
                buttonEnabled: true
            }
        })

        onMounted(() => {
            getImages()

            apiClient.get('/tags/popular').then((resp) => {
                popularTags.value = resp.data
            })
        })

        watch([checkedTags, searchString], () => {
            searching.value = true
            if (searchTimer.value) {
                clearTimeout(searchTimer.value)
            }
            searchTimer.value = setTimeout(() => {
                searchImages()
            }, 1000)
        })

        return {
            questcardStore,
            selectedImageCard,
            checkedTags,
            searchString,
            setSelectedImageCard,
            setImageOnImageCard,
            setCartCollapsed,
            setSidebarCollapsed,
            removeFromImages,
            imageUploaded,
            sideBarCollapsed,
            cartCollapsed,
            isImageDouble,
            imageResults,
            footerOptions,
            splideOptions,
            popularTags,
            searching
        }
    },
    computed: {
        sideBarArrow() {
            let iconClass = 'fa-regular fa-chevron-'
            if (this.sideBarCollapsed) {
                iconClass += 'right'
            } else {
                iconClass += 'left'
            }
            return iconClass
        },
        collapsableClass() {
            if (this.sideBarCollapsed === true) {
                return 'sidebar__collapsed'
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped lang="scss">
.image {
    &__load-more {
        //display: flex;
        display: none;
        justify-content: center;
        margin-top: 2rem;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        .item {
            &-12 {
                max-width: calc((100% / 4) - 1.5rem);
                flex: 1 1 calc((100% / 4) - 1.5rem);

                @include mq('laptop') {
                    max-width: calc((100% / 3) - 1.5rem);
                    flex: 1 1 calc((100% / 3) - 1.5rem);
                }

                @include mq('tablet') {
                    max-width: calc((100% / 2) - 1rem);
                    flex: 1 1 calc((100% / 2) - 1rem);
                }

                @include mq('mobile-sm') {
                    max-width: 100%;
                    flex: 1 1 100%;
                }
            }

            &-15, &-20 {
                max-width: calc((100% / 5) - 1.5rem);
                flex: 1 1 calc((100% / 5) - 1.5rem);

                @include mq('laptop') {
                    max-width: calc((100% / 3) - 1.5rem);
                    flex: 1 1 calc((100% / 3) - 1.5rem);
                }

                @include mq('tablet') {
                    max-width: calc((100% / 2) - 1rem);
                    flex: 1 1 calc((100% / 2) - 1rem);
                }

                @include mq('mobile-sm') {
                    max-width: 100%;
                    flex: 1 1 100%;
                }
            }
        }
    }

    &__select-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        //max-height: 80vh;
        //overflow-y: auto;

        @include mq('laptop') {
            overflow-y: visible;
        }

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }
}

.filter {
    &__checkbox-container {
        overflow-x: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__checkbox-scroll-container {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 85%);
            width: 15%;
        }
    }
}

.radio-input__container {
    display: flex;
    gap: .8rem;


    @include mq('tablet') {
        flex-direction: column;
    }
}

.sidebar__collapse-button {
    border-bottom-right-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    background: $color-black;
    color: $color-white;
    padding: .5rem;
    font-size: 1.2rem;
    cursor: pointer;
    position: fixed;
    transform: translateY(-50%);
    top: 50%;
    width: 2rem;
    height: 10rem;
    left: -.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s ease-in-out all;

    &--open {
        left: 48rem;
    }
}

.sidebar__collapse-button-mobile {
    display: none;

    @include mq('laptop') {
        border-bottom-left-radius: 1.6rem;
        border-top-left-radius: 1.6rem;
        background: #000000;
        color: #FFFFFF;
        padding: 0.5rem;
        font-size: 1.2rem;
        cursor: pointer;
        position: absolute;
        top: 50vh;
        width: 2rem;
        height: 10rem;
        right: -0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.questcard__tips {
    display: flex;

    svg {
        color: $color-secondary-greyscale;
        height: 2.4rem;
        width: 2.4rem;
        margin-right: .8rem;
    }
}
</style>
