<template>
    <div
        class="sidebar__preview"
        :class="disableMargin && 'sidebar__no-margin'"
    >
        <p class="text--extra-small">{{ questcardStore.form.questcardTitle }}</p>
        <div class="sidebar__preview-container preview-12">
            <img
                v-for="i in Array(parseInt(questcardStore.form.amountImages)).keys()"
                :src="questcardStore.getImageById(i)"/>
        </div>
    </div>
</template>

<script>
import {useQuestcardStore} from "@stores/questcardStore";

export default {
    name: "preview",
    props: {
        disableMargin: {
            type: Boolean,
            default: false
        }
    },
    setup() {
      const questcardStore = useQuestcardStore()

      return {
          questcardStore
      }
    }
}
</script>

<style scoped lang="scss">
.sidebar {
    &__preview {
        margin: 1.5rem auto;
        width: 100%;
        aspect-ratio: 2/1.25;
        border: 1px solid $color-black;
        background-color: $color-grey;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &-container {
            padding: .5rem .5rem;
            display: grid;
        }
    }

    &__no-margin {
        margin: 0 !important;
    }
}
.preview-12{
    grid-template-columns: 20% 20% 20% 20%;
    column-gap: calc(20%/3);
    row-gap: 1rem;
}
</style>
