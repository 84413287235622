<template>
    <div
        class="image-select__container"
        @click.self="buttonClicked"
        :class="isDouble ? 'image-select--double' : selected ? 'image-select--selected' : ''"
    >
        <input
            type="file"
            accept="image/*"
            class="image-select__input"
            ref="input"
            @change="selectImage"
        />
        <div
            class="image-select__trash-button"
            v-if="imageData.imagePreview"
            @click="clearImageData"
        >
            <font-awesome-icon
                icon="fa-regular fa-trash"
                class="trash-button"
            >
            </font-awesome-icon>
        </div>
        <template v-if="imageData.imagePreview">
            <img :src="imageData.imagePreview" />
        </template>
        <template v-else>
            <input-button
                :icon="buttonIcon"
                :iconLocation="'left'"
                color="outline btn--text-small"
                buttonClasses="hover--blue"
                @click.self="buttonClicked"
            >
                {{ buttonText }}
            </input-button>
            <span class="image-select__tips-text" v-if="tipsText">{{tipsText}}</span>
        </template>
    </div>
</template>

<script>
import InputButton from "@components/inputs/InputButton";
import FileUpload from "@services/fileUpload";
import apiClient from "@services/api";

import {ref} from "vue";

export default {
    name: "ImageUpload",
    props: {
        tipsText: {},
        buttonIcon: {
            default: 'fa-regular fa-camera'
        },
        buttonText: {
            default: 'Foto uploaden'
        },
        identifier: {
            type: Number,
            default: 0
        },
        selected: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: null
        },
        isDouble: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const imageData = ref({
            currentImage: null,
            imagePreview: null,
            progress: 0,
            message: null,
            currentFileId: null
        })

        const input = ref(null)

        const buttonClicked = () => {
            emit('button-clicked', props.identifier)
            input.value.click()
        }

        const clearImageData = () => {
            removeImage(imageData.value.currentFileId)
            imageData.value = {
                currentImage: null,
                imagePreview: null,
                progress: 0,
                message: null,
                currentFileId: null
            }
        }

        const removeImage = (id) => {
            if(imageData.value.currentFileId) {
                apiClient.post('deleteImage', {
                    id: id
                })
            }
        }

        const uploadImage = async () => {
            removeImage(imageData.value.currentFileId)
            FileUpload.upload(imageData.value.currentImage, (event) => {
                imageData.value.progress = Math.round((100 * event.loaded) / event.total)
            }).then((resp) => {
                imageData.value.currentFileId = resp.data.id
                emit('image-uploaded', resp.data)
            })
        }

        const selectImage = () => {
            imageData.value.currentImage = input.value.files.item(0)
            imageData.value.imagePreview = URL.createObjectURL(imageData.value.currentImage)
            uploadImage()
        }

        return {
            buttonClicked,
            imageData,
            selectImage,
            clearImageData,
            input
        }
    },
    components: {
        InputButton,
    }
}

</script>

<style scoped lang="scss">
.image-select {
    &__container {
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: .1rem solid $color-black;
        background-color: $color-secondary-sandstorm;
        cursor: pointer;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__input {
        display: none;
    }

    &__tips-text {
        margin-top: .8rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $color-secondary-greyscale;
    }

    &--selected {
        border-color: $color-blue;
        border-width: .2rem !important;

        button {
            color: $color-blue;
            border-color: $color-blue;
        }
    }

    &__trash-button {
        width: 3.2rem;
        height: 3.2rem;
        background-color: $color-white;
        border: .1rem solid $color-primary-black;
        border-radius: .6rem;
        position: absolute;
        top:2rem;
        right:2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: $color-blue;
            border-color: $color-blue;
        }

        .trash-button {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

</style>
