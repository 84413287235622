<template>
    <sidebar :class="cartCollapsed ? 'sidebar__collapsed' : ''" direction="right">
        <template #sidebar-header>
            <div class="cart__header">
                <font-awesome-icon class="cart__header-icon" icon="fa-regular fa-chevron-left"
                                   @click="subtractCartStep"/>
                <steps :steps="cartSteps"/>
                <font-awesome-icon class="cart__header-icon" icon="fa-regular fa-times" @click="$emit('close-cart')"/>
            </div>
        </template>
        <template #sidebar-content>
            <template v-if="questcardStore.form.cartStep === 1">
                <div class="sidebar__title mb-4">
                    <h3 class="title--medium">Kies de bestelopties voor je zelfgemaakte speurtocht
                    </h3>
                </div>
                <preview></preview>
                <div class="sidebar__title mt-5 mb-4">
                    <h3 class="title--medium">Oplevering</h3>
                </div>
                <div class="sidebar__options">
                    <input-radio-button
                        name="order_options"
                        text="Printbare PDF"
                        class="w-full"
                        value="only_pdf"
                        v-model="questcardStore.form.orderOptions"
                    />
                    <input-radio-button
                        name="order_options"
                        text="Printbare PDF + Drukwerk"
                        class="mt-3 w-full"
                        value="pdf_and_print"
                        v-model="questcardStore.form.orderOptions"
                    />
                </div>
            </template>
            <template v-if="(cartSteps === 3 && questcardStore.form.cartStep === 2)">
                <div class="sidebar__title mb-4">
                    <h3 class="title--medium">Kleur, formaat en aantal exemplaren drukwerk</h3>
                </div>
                <div class="sidebar__title mt-5 mb-4">
                    <h3 class="title--medium">Kleur</h3>
                </div>
                <div class="sidebar__options">
                    <input-radio-button
                        name="color_options"
                        text="Zwart-wit"
                        class="w-full"
                        value="black_white"
                        v-model="questcardStore.form.color"
                    />
                    <input-radio-button
                        name="color_options"
                        text="Kleur"
                        class="mt-3 w-full"
                        value="full_color"
                        v-model="questcardStore.form.color"
                    />
                </div>
                <div class="sidebar__title mt-5 mb-4">
                    <h3 class="title--medium">Formaat</h3>
                </div>
                <div class="sidebar__options">
                    <input-radio-button
                        name="format_options"
                        text="A4 papierformaat"
                        class="w-full"
                        value="a4"
                        v-model="questcardStore.form.format"
                    />
                    <input-radio-button
                        name="format_options"
                        text="A3 papierformaat"
                        class="mt-3 w-full"
                        value="a3"
                        v-model="questcardStore.form.format"
                    />
                </div>
                <div class="sidebar__title mt-5 mb-4">
                    <h3 class="title--medium">Aantal exemplaren</h3>
                </div>
                <div class="sidebar__options">
                    <input-radio-button
                        name="amount_options"
                        text="1 exemplaar"
                        class="w-full"
                        value="1"
                        v-model="questcardStore.form.amountPrints"
                    />
                    <input-radio-button
                        name="amount_options"
                        text="2 exemplaren"
                        class="mt-3 w-full"
                        value="2"
                        v-model="questcardStore.form.amountPrints"
                    />
                    <input-radio-button
                        name="amount_options"
                        text="3 exemplaren"
                        class="mt-3 w-full"
                        value="3"
                        v-model="questcardStore.form.amountPrints"
                    />
                </div>
            </template>
            <template
                v-if="(cartSteps === 2 && questcardStore.form.cartStep === 2) || (cartSteps === 3 && questcardStore.form.cartStep === 3)">
                <div class="sidebar__title mb-4">
                    <h3 class="title--medium">Gelukt! Je speurtocht zit in je winkelmand.</h3>
                </div>
                <div class="cart__divider mb-4"></div>
                <div class="cart__order-overview">
                    <div class="sidebar__title mt-4 mb-4">
                        <p class="text text--extra-small text--black text--bold">Overzicht bestelling</p>
                    </div>
                    <div class="cart__summary mb-4">
                        <preview :disableMargin="true"></preview>
                        <div class="cart__text-summary">
                            <p class="text text--extra-small text--black">
                                {{ summaryText.type[questcardStore.form.format] }}</p>
                            <p class="text text--extra-small text--secondary-grey mt-1">{{ summaryText.title }}</p>
                            <template v-if="cartSteps === 3">
                                <p class="text text--extra-small text--secondary-grey mt-1">
                                    {{ summaryText.color[questcardStore.form.color] }}</p>
                                <p class="text text--extra-small text--secondary-grey mt-1">
                                    {{ summaryText.amount[questcardStore.form.amountPrints] }}</p>
                                <p class="text text--extra-small text--secondary-grey mt-1">
                                    {{ summaryText.printOptions[questcardStore.form.orderOptions] }}</p>
                            </template>
                        </div>
                        <h3 class="title--extra-small">{{ price }}</h3>
                    </div>
                </div>
                <div class="cart__divider"></div>
                <div class="sidebar__title mt-4 mb-4">
                    <p class="text text--extra-small text--black text--bold">Leveringopties</p>
                    <p class="text text--extra-small text--secondary-grey mt-4">Worden gekozen en bepaald bij het
                        afrekenen</p>
                </div>
                <div class="cart__divider"></div>
                <input-button color="white"
                              class="mt-3 w-full"
                              icon="fa-regular fa-pen"
                              @click="finishOrder('anotherOne')"
                >
                    Nog een speurtocht ontwerpen
                </input-button>
                <input-button color="white"
                              class="mt-3 w-full"
                              :customIcon="true"
                              iconSrc="/images/zebraImage.svg"
                              @click="finishOrder('shop')"
                >
                    Verder winkelen op studiozebra
                </input-button>
            </template>
            <input-button
                v-if="(cartSteps === 2 && questcardStore.form.cartStep < 2) || (cartSteps === 3 && questcardStore.form.cartStep < 3)"
                class="mt-3 w-full"
                @click="advanceCartStep"
            >
                Doorgaan
            </input-button>
            <input-button
                v-if="(cartSteps === 2 && questcardStore.form.cartStep === 2) || (cartSteps === 3 && questcardStore.form.cartStep === 3)"
                class="mt-3 w-full" @click="finishOrder('cart')"
                icon="fa-regular fa-shopping-basket">Afrekenen
            </input-button>
            <form id="questcardForm" method="post" style="display:none;"
                  :action="storeUrl">
            </form>
        </template>
    </sidebar>
</template>
<script>
import Preview from "@components/sidebar/Preview";
import Steps from "@components/sidebar/Steps";
import InputRadioButton from "@components/inputs/InputRadioButton";
import InputButton from "@components/inputs/InputButton";
import Sidebar from "@layouts/Sidebar";
import apiClient from "@services/api";

import {useQuestcardStore} from "@stores/questcardStore";

import {computed, ref, watch} from "vue"

export default {
    name: "Cart",
    props: {
        cartCollapsed: {
            type: Boolean,
            default: true
        }
    },
    components: {
        InputButton,
        Preview,
        Steps,
        InputRadioButton,
        Sidebar
    },
    setup() {
        const questcardStore = useQuestcardStore()
        const cartSteps = ref(2)
        const storeUrl = process.env.MIX_STORE_URL
        const appUrl = process.env.MIX_APP_URL

        const price = computed(() => {
            let basePrice = 1.29

            if (questcardStore.form.amountImages === '15') {
                basePrice = 1.49
            }
            if (questcardStore.form.amountImages === '20') {
                basePrice = 1.69
            }

            if (questcardStore.form.questcardType !== 'existing_images') {
                basePrice = 600

                if (questcardStore.form.amountImages === '15') {
                    basePrice = 750
                }
                if (questcardStore.form.amountImages === '20') {
                    basePrice = 1000
                }
            }
            if (questcardStore.form.orderOptions === 'pdf_and_print') {
                let orderOptionBlackWhiteMultiplier = 0.5
                let orderOptionColorMultiplier = 0.75
                if (questcardStore.form.format !== 'a4') {
                    orderOptionBlackWhiteMultiplier = 0.75
                    orderOptionColorMultiplier = 0.99
                }
                if (questcardStore.form.color === 'black_white') {
                    basePrice += (parseInt(questcardStore.form.amountPrints) * orderOptionBlackWhiteMultiplier)
                } else {
                    basePrice += (parseInt(questcardStore.form.amountPrints) * orderOptionColorMultiplier)
                }
            }
            return `€ ${basePrice.toFixed(2)}`
        })

        watch(
            () => questcardStore.form.orderOptions,
            (newValue) => {
                if (newValue === 'only_pdf') {
                    cartSteps.value = 2
                } else {
                    cartSteps.value = 3
                }
            },
            {
                deep: true
            }
        )

        const summaryText = computed(() => {
            return {
                type: {
                    a4: 'A4 Speurtocht',
                    a3: 'A3 Speurtocht'
                },
                color: {
                    black_white: 'Zwart wit',
                    full_color: 'Kleur'
                },
                amount: {
                    1: '1 Exemplaar',
                    2: '2 Exemplaren',
                    3: '3 Exemplaren',
                },
                printOptions: {
                    only_pdf: 'PDF',
                    pdf_and_print: 'PDF + drukwerk'
                },
                title: questcardStore.form.questcardTitle,
            }
        })

        const advanceCartStep = () => {
            questcardStore.form.cartStep++
        }

        const subtractCartStep = () => {
            if (questcardStore.form.cartStep > 1) {
                questcardStore.form.cartStep--
            }
        }

        const finishOrder = (redirectTo = 'cart') => {
            let images = []

            questcardStore.questcards.forEach((qc) => {
                images.push({
                    id: qc.file.id,
                    order: qc.id,
                    type: questcardStore.form.questcardType !== 'existing_images' ? 'photo' : 'drawing'
                })
            })

            apiClient.post('/questcard', {
                title: questcardStore.form.questcardTitle,
                images
            }).then((resp) => {
                if (resp.status === 201) {
                    let form = document.getElementById('questcardForm')
                    form.innerHTML = form.innerHTML + `<input type="text" name="orderOptions" value="${questcardStore.form.orderOptions}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="questCardType" value="${questcardStore.form.questcardType}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="questcardTitle" value="${questcardStore.form.questcardTitle}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="questcardType" value="${questcardStore.form.questcardType}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="color" value="${questcardStore.form.color}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="format" value="${questcardStore.form.format}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="amountPrints" value="${questcardStore.form.amountPrints}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="amountImages" value="${questcardStore.form.amountImages}"/>`


                    form.innerHTML = form.innerHTML + `<input type="text" name="questcardId" value="${resp.data.id}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="redirectTo" value="${redirectTo}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="questcardUrl" value="${appUrl}/admin/questcards/${resp.data.id}"/>`
                    form.innerHTML = form.innerHTML + `<input type="text" name="questcardDownloadUrl" value="${appUrl}api/questcard/${resp.data.id}/downloadPDF"/>`
                    form.submit()
                }
            })
        }
        return {
            cartSteps,
            advanceCartStep,
            subtractCartStep,
            finishOrder,
            summaryText,
            storeUrl,
            questcardStore,
            price
        }
    }
}
</script>

<style scoped lang="scss">
.cart__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }
}

.cart__divider {
    position: relative;
    width: calc(100% - 2.4rem);
    height: 1px;

    &:after {
        content: '';
        display: block;
        width: calc(100% + 4rem);
        background: $color-black;
        height: 1px;
        position: absolute;
        top: 0;
        left: -16px;
    }
}

.cart__summary {
    display: grid;
    grid-template-columns: 35% 30% 30%;
    column-gap: 2.5%;
}
</style>
