<template>
    <div
        class="image-select__container"
        @click="buttonClicked"
        :class="isDouble ? 'image-select--double' : selected ? 'image-select--selected' : ''"
    >
        <template v-if="image">
            <img :src="image" />
        </template>
        <template v-else>
            <input-button
                :icon="buttonIcon"
                :iconLocation="'left'"
                color="outline btn--text-small"
                buttonClasses="hover--blue"
            >
                {{ buttonText }}
            </input-button>
            <span class="image-select__tips-text" v-if="tipsText">{{tipsText}}</span>
        </template>
    </div>
</template>

<script>
import InputButton from "@components/inputs/InputButton";

export default {
    name: "ImageSelect",
    props: {
        tipsText: {},
        buttonIcon: {
            default: 'fa-regular fa-camera'
        },
        buttonText: {
            default: 'Foto(\'s) uploaden'
        },
        identifier: {
            type: Number,
            default: 0
        },
        selected: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: null
        },
        isDouble: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
      const buttonClicked = () => {
          emit('button-clicked', props.identifier)
      }

      return {
          buttonClicked
      }
    },
    components: {
        InputButton,
    }
}

</script>

<style scoped lang="scss">
.image-select {
    &__container {
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: .1rem solid $color-black;
        background-color: $color-secondary-sandstorm;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    &__tips-text {
        margin-top: .8rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $color-secondary-greyscale;
    }

    &--selected {
        border-color: $color-blue;
        border-width: .2rem !important;

        button {
            color: $color-blue;
            border-color: $color-blue;
        }
    }
}

</style>
